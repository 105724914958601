import React from 'react';
import { toast } from 'react-toastify';
import Countdown from 'react-countdown';
import { Row, Col, Container, Image, Form, Button } from 'react-bootstrap';

import logo from 'assets/images/DILG LOGO.png';
import ulapLogo from 'assets/images/ulap.png';
import nicpLogo from 'assets/images/nicp.png';
import usaidLogo from 'assets/images/usaid.png';
import crayonLogo from 'assets/images/new crayon.png'; 
import ygoalLogo from 'assets/images/ygoal.png'; 
import hsiLogo from 'assets/images/hsi.png'; 
import mainImage from 'assets/images/new home2024-left.png';
import { ReactComponent as CalendarSVG } from 'assets/images/calendar.svg';

const PageOne = ({ userData, setUserData, setPage }) => {
  const countdownDate = process.env.REACT_APP_COUNTDOWN_DATE || 'September 16, 2024 08:00:00 GMT+0800';
  const eventEndDate = new Date('October 17, 2024 15:00:00 GMT+0800');

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const now = new Date();

    if (completed) {
      if (now >= eventEndDate) {
        return (
          <div className='countdown-container'>
            <span>The event has ended.</span>
          </div>
        );
      } else {
        return (
          <div className='countdown-container'>
            <span>The event has already started.</span>
          </div>
        );
      }
    } else {
      return (
        <div className='countdown-container'>
          <span>Event will begin in:</span>

          <div className='timer'>
            <div className='block'>
              <p className='days'>{days}</p>
              <span>Days</span>
            </div>

            <div className='comma'>:</div>

            <div className='block'>
              <p className='hours'>{hours}</p>
              <span>Hours</span>
            </div>

            <div className='comma'>:</div>

            <div className='block'>
              <p className='minutes'>{minutes}</p>
              <span>Minutes</span>
            </div>

            <div className='comma'>:</div>

            <div className='block'>
              <p className='seconds'>{seconds}</p>
              <span>Seconds</span>
            </div>
          </div>
        </div>
      );
    }
  };

  const handleChange = (key) => (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [key]: e.target.value,
      };
    });
  };

  const handleNextPage = () => {
    const checkValues = [
      userData?.gender,
      userData?.lastname,
      userData?.firstname,
      userData?.designation,
      userData?.emailaddress,
      userData?.mobilenumber,
      userData?.middleinitial,
    ];

    const result = checkValues.filter((data) => data === '');

    if (result.length > 0) {
      toast.error('Please fill in the empty fields');
      return;
    }

    setPage(2);
  };

  const gotoTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div className='home-main'>
        <Container>
          <Row>
            <Col md={7} className='left-container'>
              <div className='header'>
                <Image src={logo} className='d-inline-block' alt='DILG Logo' />
                <h2>
                  department of the interior and local government <span>presents</span>
                </h2>
              </div>

              <div className='main-image'>
                <Image className='image d-inline-block' src={mainImage} alt='main image' />

                <div className='date'>
                  <p>
                    “Fostering Transformative e-Governance for Sustained Local Economic Development”
                  </p>
                  <p className='white-container shadow-sm'>
                    <CalendarSVG />
                    <span>October 17, 2024 | 8:00 AM – 3:00 PM at <b>Iloilo Grand Hotel</b></span>
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5} className='right-container'>
              <Form className='form-register shadow-sm'>
                <h2>Register now!</h2>
                <p>Please FILL-OUT the required information below (Marked with *):</p>

                <div className='separator'></div>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Last Name <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.lastname}
                        onChange={handleChange('lastname')}
                        placeholder='Enter Last Name'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        First Name <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.firstname}
                        onChange={handleChange('firstname')}
                        placeholder='Enter First Name'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Middle Initial <b>*</b>
                      </Form.Label>
                      <Form.Control
                        type='text'
                        value={userData?.middleinitial}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[A-Za-z]{0,5}$/.test(value)) {
                            handleChange('middleinitial')(e);
                          }
                        }}
                        placeholder='Enter Middle Initial'
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Designation <b>*</b>
                      </Form.Label>
                      <Form.Control
                        value={userData?.designation}
                        onChange={handleChange('designation')}
                        placeholder='Enter Designation'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Email Address <b>*</b>
                      </Form.Label>
                      <Form.Control
                        type='email'
                        value={userData?.emailaddress}
                        onChange={handleChange('emailaddress')}
                        placeholder='Enter Email Address'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col>
                    <Form.Group className='form-group'>
                    <Form.Label>
                      Mobile Number <b>*</b>
                    </Form.Label>
                    <Form.Control
                      type='text'
                      value={userData?.mobilenumber}
                      onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,11}$/.test(value)) {
                        handleChange('mobilenumber')(e);
                      }
                    }}
                    placeholder='09700000000'
                    />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className='form-group'>
                      <Form.Label>
                        Gender <b>*</b>
                      </Form.Label>
                      <Form.Select
                        value={userData?.gender}
                        onChange={handleChange('gender')}
                        aria-label='Select gender'
                      >
                        <option value='' hidden>
                          Select Gender
                        </option>
                        <option value='male'>Male</option>
                        <option value='female'>Female</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col className='submit-container'>
                    <Button variant='primary' onClick={handleNextPage}>
                      Register Now!
                    </Button>
                    <p>Other information will be filled in the next page</p>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='countdown'>
        <Container>
          <Row>
            <Col>
              <Countdown date={countdownDate} renderer={renderer} />
            </Col>
          </Row>
        </Container>
      </div>

      <div className='content'>
        <Container>
          <Row className='mb-5'>
            <Col className='content-text'>
                
              <h2 style={{ marginBottom: '3px' }}>
                EODB Forum 2024:
              </h2>
              <h2 style={{ marginTop: '0px' }}>
                “Fostering Transformative <br></br>e-Governance for Sustained Local Economic Development”
              </h2>

              <p>
              While there are fast-growing digital platforms available to promote efficient
              delivery of services at the local level, there are only number of LGUs that
              are capable of digitalizing their various government transactions and
              services attributing to various reasons such as financial, personnel, and
              infrastructure constraints. Digital platforms not only promote efficient delivery
              of government services but it also helps local economic growth and reduce
              graft and corruption. It also complements the implementation of the Ease of
              Doing Business and Efficient Government Service Delivery Act
              (EODB-EGSDA) of 2018 at the local level.
              </p>

              <p>
              Moreover, to provide equal opportunities to all local government units
              (LGUs) in streamlining their various transactions and services through
              digitalization, the Department of Information and Communications
              Technology, (DICT) developed the Electronic Local Government Unit (eLGU)
              System. The system is one of the components of the eGOV PH Super
              Application which provides a one-stop shop platform for a wide range of
              local government services that includes: local tax processing, local
              registration, basic certifications and licensing, and information
              Dissemination.
              </p>

              <p>
              To further advocate the automation/digitalization in local government
              transactions, the DILG, through the Bureau of Local Government
              Development (BLGD) will conduct its annual EODB Forum focusing on the
              role of digital transformation in enhancing government efficiency and driving
              economic growth in the localities.
              </p>
              
            </Col>
            {/* <Col className='content-image'>
              <Image alt='content image' className='d-inline-block' src={contentImage} />
            </Col> */}
          </Row>

          <Row className='mb-5'>
            <Col className='panel-forum'>
              <h2>Panel Forum</h2>

              <div className='list'>
                <div className='data shadow-sm'>
                  <h4>
                    Session 1: Government Streamlining Regulations
                  </h4>
                  <p>
                    The first session will introduce the efforts of the National Government Agencies in
                    featuring laws enacting the streamlining regulations on permitting process. This
                    offers an opportunity to invite the LGUs to adapt to regulations construed by the
                    national policies to foster sustained local economic development.
                  </p>
                </div>

                <div className='data shadow-sm'>
                  <h4>
                    Session 2: Digital Transformation at the Local Level
                  </h4>
                  <p>
                  The second session highlights the framework imploring the digital transformation at 
                  the local level. It aims to raise awareness and understanding on the importance of 
                  digital payments as well as encourage LGUs to utilize cashless transactions particularly 
                  in business regulation and tax collection.
                  </p>
                </div>

                <div className='data shadow-sm'>
                  <h4>
                    Session 3: Sharing of LGU Innovations in Digital Transformations
                  </h4>
                  <p>
                  The third session will illustrate various practices adopted by local government units in 
                  light of Digital Transformation. In this session, it aims to draw inspiration to other 
                  participating LGUs in the Forum to adopt the same in their respective localities.
                  </p>
                </div>

                <div className='data shadow-sm'>
                  <h4>Session 4: Panel Discussion on the Benefits of Government&apos;s EODB initiatives (Fireside chat)</h4>
                  <p>
                    The fourth session will be sharing of the private sector (from telco, energy, real estate, 
                    local chamber) on the impact of EODB and solicit possible improvement/enhancement of the 
                    implementation of EODB.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className='register-button'>
              <Button variant='primary' onClick={gotoTop}>
                Register Now!
              </Button>
            </Col>
          </Row>
        </Container>
      </div>

      <div className='our-partners'>
        <Container>
          <Row>
            <Col className='partners-container text-center'>
              <h2>OUR PARTNERS</h2>

              <div className='partners'>
                <Image alt='nicp logo' src={nicpLogo} className='d-inline-block align-top' />
                <Image alt='Ulap logo' src={ulapLogo} className='d-inline-block align-top' />
                <Image alt='usaid logo' src={usaidLogo} className='d-inline-block align-top' />
                <Image alt='crayon logo' src={crayonLogo} className='d-inline-block align-top' />
                <Image alt='Ygoal logo' src={ygoalLogo} className='d-inline-block align-top' />
                <Image alt='hsi logo' src={hsiLogo} className='d-inline-block align-top' />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PageOne;
